import request from '@/utils/request';

export function updatePass(data) {
    return request({
        'url': '/api/v1/admin/user/modify_password',
        'method': 'post',
        data
    });
}

export function updateUserInfo(data) {
    return request({
        'url': '/api/v1/admin/user',
        'method': 'put',
        data
    });
}

<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <el-row :gutter="20">
        <el-col
          :span="6"
          class="info"
        >
          <h5>个人信息</h5>
          <div class="d-flex">
            <span><b>登录账号:</b></span>
            <div class="flex1">
              {{ user.username }}
            </div>
          </div>
          <div class="d-flex">
            <span><b>用户昵称:</b></span>
            <div class="flex1">
              {{ user.nickname }}
            </div>
          </div>
          <div class="d-flex">
            <span><b>手机号码:</b></span>
            <div class="flex1">
              {{ user.phone }}
            </div>
          </div>
          <div class="d-flex">
            <span><b>安全设置:</b></span>
            <div
              class="flex1 divModify"
              @click="$refs.pass.dialog = true"
            >
              修改密码
            </div>
          </div>
        </el-col>

        <el-col :span="18">
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="用户资料"
              name="first"
            >
              <el-form
                ref="personaInfoFrom"
                :label-position="labelPosition"
                :model="personaInfoFrom"
                :rules="personalInfoFromRules"
                label-width="80px"
              >
                <el-form-item
                  label="用户昵称"
                  label-width="80px"
                  prop="nickname"
                >
                  <el-input v-model="personaInfoFrom.nickname" />
                </el-form-item>
                <el-form-item
                  label="手机号码"
                  label-width="80px"
                  prop="phone"
                >
                  <el-input
                    v-model="personaInfoFrom.phone"
                    label-width="120"
                  />
                </el-form-item>
                <el-form-item class="text-center">
                  <el-button
                    :loading="saveLoading"
                    type="primary"
                    @click="submitForm"
                  >
                    保存
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane
              label="告警订阅"
              name="second"
            >
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                class="demo-ruleForm"
                label-width="80px"
              >
                <el-form-item label="提示告警">
                  <el-checkbox-group
                    v-model="checkbox.one.notificationMethodCheckList"
                    border
                    size="mini"
                  >
                    <el-checkbox
                      v-for="(item, index) in checkbox.one
                        .notificationMethodList"
                      :key="index"
                      :disabled="item === '系统通知'"
                      :label="item"
                      border
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="一般告警">
                  <el-checkbox-group
                    v-model="checkbox.two.notificationMethodCheckList"
                    border
                    size="mini"
                  >
                    <el-checkbox
                      v-for="(item, index) in checkbox.two
                        .notificationMethodList"
                      :key="index"
                      :disabled="item === '系统通知'"
                      :label="item"
                      border
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="重要告警">
                  <el-checkbox-group
                    v-model="checkbox.three.notificationMethodCheckList"
                    border
                    size="mini"
                  >
                    <el-checkbox
                      v-for="(item, index) in checkbox.three
                        .notificationMethodList"
                      :key="index"
                      :disabled="item === '系统通知'"
                      :label="item"
                      border
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="严重告警">
                  <el-checkbox-group
                    v-model="checkbox.four.notificationMethodCheckList"
                    border
                    size="mini"
                  >
                    <el-checkbox
                      v-for="(item, index) in checkbox.four
                        .notificationMethodList"
                      :key="index"
                      :disabled="item === '系统通知'"
                      :label="item"
                      border
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item class="text-center">
                  <el-button type="primary">
                    保存配置
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
  <UpdatePass ref="pass" />
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'
import UpdatePass from './center/updatePass'
import {mapGetters} from 'vuex'
import {updateUserInfo} from '@/api/system/user'

export default {
	name: 'PersonalCenter',
	components: {
		BreadCrumb,
		UpdatePass
	},
	data() {
		return {
			activeName: 'first',
			labelPosition: 'right',
			saveLoading: false,
			personaInfoFrom: {userId: '', username: '', nickname: '', phone: ''},
			personalInfoFromRules: {
				nickname: [
					{
						required: true,
						message: '昵称不能为空!',
						trigger: 'blur'
					},
					{
						min: 2,
						max: 8,
						message: '昵称只能填写2-8个字符!',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '手机号不能为空!',
						trigger: 'blur'
					}
				]
			},
			checkbox: {
				one: {
					notificationMethodList: ['系统通知', '邮件通知', '短信通知'],
					notificationMethodCheckList: ['系统通知']
				},
				two: {
					notificationMethodList: ['系统通知', '邮件通知', '短信通知'],
					notificationMethodCheckList: ['系统通知']
				},
				three: {
					notificationMethodList: ['系统通知', '邮件通知', '短信通知'],
					notificationMethodCheckList: ['系统通知']
				},
				four: {
					notificationMethodList: ['系统通知', '邮件通知', '短信通知'],
					notificationMethodCheckList: ['系统通知']
				}
			}
		}
	},
	created() {
		this.personaInfoFrom = {
			nickname: this.user.nickname,
			phone: this.user.phone,
			username: this.user.username,
			userId: this.user.userId
		}
	},
	mounted: function () {
	},
	computed: {
		...mapGetters(['user'])
	},
	methods: {
		submitForm() {
			const that = this
			this.$refs['personaInfoFrom'].validate(async valid => {
				if (valid) {
					that.saveLoading = true
					const {code} = await updateUserInfo(that.personaInfoFrom)
					if (code === 200) {
						that.$message.success('保存信息成功!')
						this.$store.dispatch('GetInfo').then(() => {
						})
					}
					that.saveLoading = false
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style scoped>
.info h5 {
	height: 40px;
	padding: 0;
	line-height: 40px;
	margin: 0 0 10px 0;
	border-bottom: 2px solid #e4e7ed;
}

.info {
	height: auto;
}

.info {
	min-height: 50px;
}

.info > div {
	margin-top: 10px;
	margin-left: 20px;
}

.info > div span {
	margin-right: 15px;
}

.info > div div {
	color: #000;
}

.info .divModify {
	cursor: pointer;
	color: #409eff;
}

.el-checkbox-group {
	position: relative;
	top: 7px;
	left: 30px;
}
</style>

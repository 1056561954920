<template>
  <el-dialog
    v-model="dialog"
    title="修改密码"
    @close="resetForm"
  >
    <el-form
      ref="passwordObject"
      :model="passwordObject"
      :rules="passwordRules"
    >
      <el-form-item
        :label-width="formLabelWidth"
        label="原密码"
        prop="oldPassword"
      >
        <el-input
          v-model="passwordObject.oldPassword"
          autocomplete="off"
          placeholder="请输入原密码"
          type="password"
        />
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        label="新密码"
        prop="newPassword"
      >
        <el-input
          v-model="passwordObject.newPassword"
          autocomplete="off"
          placeholder="请输入新密码"
          type="password"
        />
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        label="再次输入新密码"
        prop="newTowPassword"
      >
        <el-input
          v-model="passwordObject.newTowPassword"
          autocomplete="off"
          placeholder="请再次输入新密码"
          type="password"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">

        <el-button
          type="primary"
          @click="modifyPassword"
        >确 定</el-button>
        <el-button @click="cancelModify">取 消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {mapGetters} from 'vuex';
import {updatePass} from '@/api/system/user';

export default {
	'name': 'UpdatePass',
	'components': {},
	data() {
		return {
			'formLabelWidth': '120px',
			'dialog': false,
			'passwordObject': {
				'oldPassword': '',
				'newPassword': '',
				'newTowPassword': ''
			},
			'passwordRules': {
				'oldPassword': [{
					'required': true,
					'message': '原密码不可为空',
					'trigger': 'blur'
				},
					{
						'min': 6,
						'max': 12,
						'message': '请输入正确的密码格式6-12位纯数字!',
						'trigger': 'blur'
					}
				],
				'newPassword': [{
					'required': true,
					'message': '原密码不可为空',
					'trigger': 'blur'
				},
					{
						'min': 6,
						'max': 12,
						'message': '请输入正确的密码格式6-12位纯数字!',
						'trigger': 'blur'
					}
				],
				'newTowPassword': [{
					'required': true,
					'message': '原密码不可为空',
					'trigger': 'blur'
				},
					{
						'min': 6,
						'max': 12,
						'message': '请输入正确的密码格式6-12位纯数字!',
						'trigger': 'blur'
					}
				]
			}
		};
	},
	'computed': {
		...mapGetters([
			'user'
		])
	},
	methods: {
		modifyPassword() {
			const that = this;

			that.$refs.passwordObject.validate(async valid => {
				let {
					oldPassword,
					newPassword,
					newTowPassword
				} = that.passwordObject;

				if (valid) {
					const {code, message} = await updatePass({
						'oldPassword': oldPassword,
						'newPassword': newPassword,
						'newTowPassword': newTowPassword
					})
					that.dialogFormVisible = false;
					if (code === 400) {
						this.$message.error(message);
					} else {
						this.$message.success("密码修改成功,请重新登录!");
						setTimeout(() => {
							this.$store.dispatch('LogOut').then(() => {
								location.reload()
							})
						}, 1500)
					}
				} else {
					return false;
				}
			});
		},
		cancelModify() {
			this.dialog = false
			this.resetForm()
		},
		resetForm() {
			this.$refs['passwordObject'].resetFields();
			this.passwordObject = {
				'oldPassword': '',
				'newPassword': '',
				'newTowPassword': ''
			}
		}
	}
}
</script>
<style>

</style>
